import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvironmentVariableConfig } from './app/models/EnvironmrntVarilableConfig.model';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
declare global {
  interface Window {
    app: any;
    environmentVariableConfig: EnvironmentVariableConfig;
  }
}

window.app = window.app || { config: {} };
window.environmentVariableConfig = window.environmentVariableConfig || ({} as EnvironmentVariableConfig);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
