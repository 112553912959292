import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ADAPTMsalService } from '../../../services/adapt-msal.service';

@Component({
	selector: 'host-app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponentLib implements OnInit {
	public msalObj: MsalService;
	public loginHint: string;
	loginDisplay = false;
	constructor(public adaptMsalService: ADAPTMsalService) { }

	ngOnInit(): void {

		this.msalObj = this.adaptMsalService.getMsal();
		const auth = this.adaptMsalService.isAuthenticated(); //check if login is authenticated

		if (this.msalObj && auth) {
			this.msalObj.handleRedirectObservable().subscribe({  //to handle redirection
				next: (result: any) => {
					console.log("auth", result);
					if (result) {
						this.msalObj.instance.setActiveAccount(result.account);
						this.loginHint = result.idTokenClaims['preferred_username'];
						if (this.loginHint === null) {
							window.dispatchEvent(new CustomEvent('navigateToLogin')); // dispatch navigateToLogin event to the respective application
							this.adaptMsalService.logOut(); // trigger logout function
						}
					}
					this.setLoginDisplay();   // Sets login display when using redirects(currently not used)
				},
				error: (error) => {
					console.log("autherr", error);
				}
			})
		} else {
			window.dispatchEvent(new CustomEvent('navigateToLogin'));// dispatch navigateToLogin event to the respective application
			this.adaptMsalService.clearlocalStorage(); // to clear data in local storage
			this.adaptMsalService.clearsessionStorage(); // to clear data in session storage
		}

	}

	setLoginDisplay() {
		this.loginDisplay = this.msalObj.instance.getAllAccounts().length > 0;
	}

}
