import { Injectable } from '@angular/core';
import { ADAPTMsalService } from "./adapt-msal.service";

@Injectable({
    providedIn: "root"
})
export class LoginService {

    constructor(public adaptMsalService: ADAPTMsalService) {
    }

    public login(): void {
        this.adaptMsalService.login();
    }

    public logOut(): void {
        this.adaptMsalService.logOut();
    }

}
