import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) { }

    openConfirmDialog(message: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: message
            }
        });
    }

    // show dialog, after click on ok send responce
    showDialogConfirm(message: string): Observable<string> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            message: message
        };
        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

        return dialogRef.afterClosed().pipe(
            map((result: string) => {
                if (result === 'Ok') {
                    return 'ok';
                } else {
                    return 'cancel';
                }
            })
        );
    }
}
