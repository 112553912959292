import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    formatDropdownObject(inputArray: Array<any>, valueNode: string, textNode: string): Array<any> {
        const outputArray: Array<any>[] = [];
        for (const item of inputArray) {
            const obj: any = {
                id: '',
                text: ''
            };
            obj.id = item[valueNode];
            obj.text = item[textNode];
            outputArray.push(obj);
        }
        return outputArray;
    }

    formatDropdownAsOrderIdAndCategoryValue(inputArray: Array<any>, valueNode: string, textNode: string): Array<any> {
        const outputArray: Array<any>[] = [];
        for (const item of inputArray) {
            const obj: any = {
                orderId: '',
                categoryValue: ''
            };
            obj.orderId = item[valueNode];
            obj.categoryValue = item[textNode];
            outputArray.push(obj);
        }
        return outputArray;
    }
}
