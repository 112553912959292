import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponentLib } from './login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdaptDropdownComponent } from '../adapt-dropdown/adapt-dropdown.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [
    LoginComponentLib,
    LandingPageComponent,
    AdaptDropdownComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule
  ],
  exports: [AdaptDropdownComponent,]
})
export class LoginModule { }
