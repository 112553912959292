<div *ngIf="loaderFlag" class="spinner-container">
  <div class="oval-container">
    <div class="oval-one"></div>
    <div class="oval-two"></div>
    <div class="oval-three"></div>
  </div>
  <div class="spinner-text-container">
    <span class="spinner-text">
      {{auth_content}}
    </span>
  </div>
</div>