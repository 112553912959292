<div class="login_page">
    <img id="accenture_logo" src="../../../../assets/app_images/accenture_logo.svg" alt="accenture_logo" width="163.4"
        height="43.1" />
    <div class="input_container">
        <div class="welcome-image-container">
            <img id="welcome_logo" class="welcomelogo" src="../../../../assets/app_images/bG2.svg" width="288px"
                height="188px" alt="accenture_logo" />
            <h1 class="text-container">{{welcomeText}}</h1>
            <h4 class="subtitle">{{subtitle}}</h4>
        </div>
        <div class="container">
            <div class="white_box">
                <div class="adaptversion">
                    <app-adapt-dropdown class="adaptVersion-dropdown" aria-placeholder="ss"
                        [dropDownOptions]="adaptDropdownOptions" [defaultValue]="adaptDropdownOptions[0]"
                        (selectedChange)="onChangeSelectedFieldAdaptVersion($event)"
                        [requiredDropDown]="true"></app-adapt-dropdown>
                </div>
                <div class="targetsystem">
                    <app-adapt-dropdown class="targetVersion-dropdown" [dropDownOptions]="targetSystemOption"
                        (selectedChange)="onChangeSelectedFieldTargetSystem($event)"
                        [defaultValue]="targetSystemOption[0]" [requiredDropDown]="true">
                    </app-adapt-dropdown>
                </div>
            </div>
            <div id="loginbutton">
                <div class="arrwcrcle" [ngClass]="defaultValue === select_targetSystem 
                ? 'disabled' : ''">
                    <img class="icon" onkeydown="" (click)="onClick()" id="login_icon" 
                    src="../../../assets/icon_images/Login.svg" width="36" 
                    height="36" alt="Save icon" />
                </div>
            </div>
        </div>
    </div>
</div>