import { Component, Input, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ADAPTMsalService } from '../../../services/adapt-msal.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { variables } from 'src/constants';

@Component({
    selector: 'host-app-token-loader',
    templateUrl: './token-loader.component.html',
    styleUrls: ['./token-loader.component.scss']
})
export class TokenLoaderComponentLib implements OnInit {

    constructor(private adaptMsalService: ADAPTMsalService,
        public loginService: LoginService,
        public dialogService: DialogService) { }

    @Input() public loginHint: string;
    public msalObj: MsalService;
    loaderFlag: boolean = true;
    private scope: string[] = [
        `${window.environmentVariableConfig.accentureADConfig.clientId}/.default`
    ];
    public authParams = {
        scopes: this.scope,
        loginHint: "",
        account: this.adaptMsalService.getAccount()
    };
    auth_content: string = variables.auth.content;

    ngOnInit(): void {
        if (!localStorage.getItem("msal.accesstoken")) {
            this.authParams.loginHint = this.loginHint;
            this.authParams.account = this.adaptMsalService.getAccount();
            setTimeout(() => {
                this.adaptMsalService
                    .getMsal()
                    .acquireTokenSilent(this.authParams) //Attempts to acquire an access token for the account from the user token cache.
                    .subscribe((token) => {
                        if (token) {
                            localStorage.setItem(
                                "msal.accesstoken",
                                token.accessToken
                            );
                            let evt = new CustomEvent("msalAccessTokenChanged", { detail: { token: token.accessToken } });
                            window.dispatchEvent(evt);
                            sessionStorage.setItem("account", this.adaptMsalService.getAccount().localAccountId);
                            if (sessionStorage.getItem("account") !== "") {
                                window.dispatchEvent(new CustomEvent("toHome"));
                                this.loaderFlag = false;
                            } else {
                                this.adaptMsalService.clearlocalStorage();
                                window.dispatchEvent(new CustomEvent('navigateToLogin'));
                            }
                        }
                    }, (error) => {
                        console.error("Failed to acquire Token" + error);
                        this.adaptMsalService.clearlocalStorage();
                        window.dispatchEvent(new CustomEvent('navigateToLogin')); // dispatch navigateToLogin event to the respective application when failed to acquire token
                    })
            }, 700);
        } else {
            window.dispatchEvent(new CustomEvent('toHome'));// dispatch toHome event to the respective application on successful retrieval of token
        }
    }
}
