import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-adapt-dropdown',
  templateUrl: './adapt-dropdown.component.html',
  styleUrls: ['./adapt-dropdown.component.scss']
})

export class AdaptDropdownComponent {
  @Input() dropDownLabel: any;          // Variable to display label name
  @Input() defaultValue: any;       // Variable for default name in the dropdown
  @Input() dropDownOptions: Array<any>; // Variable to display options
  @Input() disabledDropDown: boolean;   // Variable to disable the dropdown
  @Input() requiredDropDown: boolean;   // Variable for Mandatory Select
  @Input() disableIndexArray: Array<any> = []; //Variable to store index of already selected values from dropdown
  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();
  disableIndex: boolean = false;

  onChangeValues(event: any): void {
    this.selectedChange.emit(event);
    this.defaultValue = this.dropDownOptions.find(x => x.orderId == event);
  }
  isDisabled(value: any): boolean {
    return this.disableIndexArray.includes(value);
  }
}
