import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { Location } from '@angular/common';
import { PublicClientApplication, LogLevel, BrowserCacheLocation } from '@azure/msal-browser';
import { MSALConfig } from '../models/EnvironmrntVarilableConfig.model';

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
@Injectable({
    providedIn: 'root'
})
export class ADAPTMsalService {

    public msalService: MsalService;
    private config: MSALConfig;
    private msalConfig: Configuration;
    private excludeLocalKeys: string[] = ['MV_APPLICATION_THEME'];
    constructor(private location: Location) { }

    public getMsal(clientId?: any): MsalService {
        if (!this.msalService) {
            if (!clientId) {
                clientId = localStorage.getItem('msal.clientid');
            }
            if (clientId && window.environmentVariableConfig.accentureADConfig.clientId) {
                localStorage.setItem('msal.clientid', clientId);
                if (
                    clientId ===
                    window.environmentVariableConfig.accentureADConfig.clientId
                ) {
                    this.config =
                        window.environmentVariableConfig.accentureADConfig;
                }
                this.msalConfig = {
                    auth: {
                        authority:
                            window.environmentVariableConfig.accentureADConfig
                                .msalAuthorityURL +
                            this.config.tenantId +
                            '/', // The app's sign-in audience (tenant ID)
                        clientId: this.config.clientId, // Application (client) ID from the app registration
                        redirectUri: this.config.redirectUri, // This is application redirect URI
                        navigateToLoginRequestUrl: false,
                        validateAuthority: true,
                        postLogoutRedirectUri: this.config.redirectUri
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: false
                    }
                };

                const pca = new PublicClientApplication({
                    auth: {
                        authority:
                            window.environmentVariableConfig.accentureADConfig
                                .msalAuthorityURL +
                            this.config.tenantId +
                            '/',
                        clientId: this.config.clientId,
                        redirectUri: this.config.redirectUri,
                        navigateToLoginRequestUrl: false,
                        postLogoutRedirectUri: this.config.redirectUri
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage,
                        storeAuthStateInCookie: isIE, // set to true for IE 11
                    },
                    system: {
                        loggerOptions: {
                            loggerCallback,
                            logLevel: LogLevel.Info,
                            piiLoggingEnabled: false // PiiLoggingEnabled enables you to log organizational data (PII) if set to true
                        }
                    }
                })
                this.msalService = new MsalService(pca, this.location);
            }
        }
        return this.msalService;
    }

    public async login(): Promise<void> {
        if (localStorage.getItem('msal.accesstoken')) {
            if (this.isTokenInvalid()) {
                this.clearlocalStorage();
                this.clearsessionStorage();
            }
        }
        //To clear the active interaction status of the microsoft
        const account = this.getMsal().instance.getActiveAccount();
        if (!account) {
            this.clearsessionStorage();
            await this.getMsal().instance.loginRedirect();
        }
    }
    public logOut(): void {
        this.getMsal().instance.logout();
        this.clearlocalStorage();
        this.clearsessionStorage();
    }

    public isAuthenticated(): boolean {
        if (!this.getMsal()) {
            return false;
        }

        if (!this.isTokenInvalid()) {
            return false;
        }
        return true;
    }

    public isTokenInvalid(): boolean {
        const exp: number = + this.getAccount()?.idTokenClaims['exp'];
        if (Date.now() >= exp * 1000) {  //to check the expiration  of the token
            this.clearlocalStorage();
            this.clearsessionStorage();
            return false;
        } else {
            return true;
        }
    }

    public getAccount(): any {
        const userName = this.getMsal().instance.getActiveAccount()?.username ?? "";
        localStorage.setItem("userName", userName);
        return this.getMsal().instance.getActiveAccount();
    }

    public isAuthorized(): boolean {
        if (!this.isAuthenticated()) {
            return false;
        }
        if (localStorage.getItem('msal.accesstoken')) {
            return true;
        } else {
            this.logOut();
            return false;
        }
    }

    public getAccessToken(): any {
        if (this.isAuthorized()) {
            return localStorage.getItem('msal.accesstoken');
        } else {
            this.clearlocalStorage();
            this.clearsessionStorage();
            this.logOut();
        }
    }
    //to clear local storage data
    public clearlocalStorage(): void {
        Object.keys(window.localStorage).forEach((key) => {
            if (!this.excludeLocalKeys.includes(key)) {
                window.localStorage.removeItem(key);
            }
        });
    }
    //to clear session storage data
    public clearsessionStorage(): void {
        sessionStorage.clear();
    }
}

