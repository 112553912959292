import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ADAPTMsalService } from '../services/adapt-msal.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'host-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponentLib implements OnInit {
  loginSuccess: boolean;
  constructor(public router: Router,
    public adaptMsalService: ADAPTMsalService,
    public loginService: LoginService) { }

  ngOnInit(): void {
    if (this.adaptMsalService.isAuthorized()) {
      void this.router.navigateByUrl('/auth');
    } else {
      void this.router.navigateByUrl('/login');
      this.adaptMsalService.clearlocalStorage();
    }
    this.loginToAD();
  }


  // login to application by calling msal login method
  public loginToAD(): void {
    this.adaptMsalService.getMsal(window.environmentVariableConfig.accentureADConfig.clientId);
    this.loginService.login();
    this.loginSuccess = true;
  }

}
