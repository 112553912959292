import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ADAPTMsalService } from './adapt-msal.service';

@Injectable()
export class ADPTRouteAuthorizationService implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private adaptMsalService: ADAPTMsalService,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAuthenticated = this.adaptMsalService.isAuthenticated();
        if (!isAuthenticated) {
            this.router.navigateByUrl('/');
        }
        return isAuthenticated;
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    public isBrowserSupported(): boolean {
        return navigator.appName !== 'Microsoft Internet Explorer';
    }
}