import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router
  ) { }
  public authFlag: any = false;
  ngOnInit(): void {
    this.authFlag = JSON.parse(sessionStorage.getItem('AuthFlag') as any);

    window.addEventListener('navigateToLogin', (evt) => {
      this.navigatetoLogin()
    });

    window.addEventListener('toHome', (evt) => {
      this.navigatetoHome()
    });

  }

  private navigatetoHome() {
    this.authFlag = true;
    sessionStorage.setItem('AuthFlag', JSON.stringify(true));
    this.router.navigateByUrl('/welcome');
  }

  private navigatetoLogin(): void {
    this.router.navigateByUrl('/login');
  }

}


