import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponentLib } from './login/login.component';
import { AuthenticationComponentLib } from './login/msal/authentication/authentication.component';
import { ADPTRouteAuthorizationService } from './services/adapt-route-authorization.service';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponentLib },
    { path: 'auth', component: AuthenticationComponentLib },
    {
        path: 'welcome',
        pathMatch: 'full',
        loadChildren: () =>
            import('./login/landing-page/landing-page.module').then((m) => m.LandingPageModule),
        canActivate: [ADPTRouteAuthorizationService]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule { }
