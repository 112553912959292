import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OverlayModule } from '@angular/cdk/overlay';
import { LoginModule } from './login/login.module';
import { AuthenticationComponentLib } from './login/msal/authentication/authentication.component';
import { TokenLoaderComponentLib } from './login/msal/token-loader/token-loader.component';
import { AppInitService } from './services/app-init.service';
import { ADPTRouteAuthorizationService } from './services/adapt-route-authorization.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';


export function loadConfigurations(configService: AppInitService) {
  return () => configService.loadConfiguration();
}
@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponentLib,
    TokenLoaderComponentLib
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatSnackBarModule,
    LoginModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [AppInitService, HttpClient],
      multi: true
    },
    ADPTRouteAuthorizationService,
    { provide: APP_BASE_HREF, useValue: '/' },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
