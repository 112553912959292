<div class="adapt-dropdown">
  <mat-label class="margin-label">{{dropDownLabel}}</mat-label>
  <mat-form-field>
    <select *ngIf="(defaultValue !== undefined && defaultValue !== null && defaultValue !== '')" matNativeControl
      (change)="onChangeValues($event.target.value)" [ngModel]="defaultValue.orderId" [disabled]="disabledDropDown"
      [required]="requiredDropDown" id="dropDownOptions">
      <option *ngFor="let option of dropDownOptions" [value]="option.orderId"
        [disabled]="isDisabled(option.categoryValue)">
        {{option.categoryValue}}
      </option>
    </select>
    <select *ngIf="(defaultValue === undefined || defaultValue === null || defaultValue === '')" matNativeControl
      (ngModelChange)="onChangeValues($event)" [ngModel]="" [disabled]="disabledDropDown" [required]="requiredDropDown"
      id="dropDownOptions">
      <option *ngFor="let option of dropDownOptions" [value]="option.orderId">
        {{option.categoryValue}}
      </option>
    </select>
  </mat-form-field>
</div>