import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { EnvironmentVariableConfig } from '../models/EnvironmrntVarilableConfig.model';


@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    private config: EnvironmentVariableConfig;
    private readonly configURL =
        './app/appConfig/environment-variable-config.json';
    private httpClient: HttpClient;
    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    public async loadConfiguration(): Promise<any> {
        return this.httpClient
            .get(this.configURL)
            .pipe((settings) => settings)
            .toPromise()
            .then((settings: any) => {
                this.config = settings;
                window.environmentVariableConfig = this.config;
            });
    }
}