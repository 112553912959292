import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { ADAPTMsalService } from '../../services/adapt-msal.service';
import { LoginService } from '../../services/login.service';
import { variables } from './../../../constants';
import { DialogService } from './../../services/dialog.service';
import { environmentDevelopment } from './../../../environments/environment.dev';
import { environmentUat } from './../../../environments/environment.uat';
import { environmentProd } from './../../../environments/environment.prod';
import * as data from './../../../assets/jsonData/dropdownData.json';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {
  categoryType: string;
  adaptDropdownData: Array<any> = [];
  adaptDropdownOptions: Array<any> = [];
  targetSystemOption: Array<any> = [];
  masterOption: Array<any> = [];
  integrityOptions: Array<any> = [];
  selectedFieldId: any;
  dropdownValue: any;
  selectedFieldVersionId: any;
  dropdownAdaptVersionValue: any;
  dropdownTargetSystemValue: any;
  masterSelected: boolean = true;
  select_adaptVersion: string = variables.landing.select_adaptversion;
  select_targetSystem: string = variables.landing.select_targetSystem;
  defaultValue: string = variables.landing.select_targetSystem;
  mdmRedirectUrl: string = window.environmentVariableConfig.apiUrlMDM;
  corrossionRedirectUrl: string = window.environmentVariableConfig.apiUrlCorrossion;
  maximoRedirectUrl: string = window.environmentVariableConfig.apiUrlMaximo; 
  adaptVersion: Array<any> = data.adaptVersion;
  targetSystemForMaster: Array<any> = data.targetSystemForMaster;
  targetSystemForIntegrity: Array<any> = data.targetSystemForIntegrity;
  masterData: string = variables.landing.master_data;
  welcomeText: string = variables.landing.welcome_text;
  subtitle: string = variables.landing.subtitle;
  sapRedirectUrl = window.environmentVariableConfig.apiUrlSAP;
  geapmRedirectUrl = window.environmentVariableConfig.apiUrlGEAPM;
  bperpRedirectUrl = window.environmentVariableConfig.apiUrlBPERP;
  cfihosRedirectUrl = window.environmentVariableConfig.apiUrlCfihos;
  guardianRedirectUrl = window.environmentVariableConfig.apiUrlGuardian;
  genAIRedirectUrl = window.environmentVariableConfig.apiUrlGenAI;

  constructor(public router: Router,
    public adaptMsalService: ADAPTMsalService,
    public loginService: LoginService,
    private utilityService: UtilityService,
    public dialogService: DialogService) {
      this.ngOnDestroy();
  }

  ngOnInit(): void {
    this.getAdaptVersionDropDown();
    this.getTargetSystemDropDown();
    this.getTargetSystemMaster();
    this.getTargetSystemIntegrity();
  }

  //dropdownvalues for adaptversion dropdown
  getAdaptVersionDropDown(): void {
    this.adaptDropdownOptions = this.utilityService.formatDropdownAsOrderIdAndCategoryValue(this.adaptVersion, 'orderId', 'categoryValue');
    this.adaptDropdownOptions.unshift({ categoryValue: this.select_adaptVersion, orderId: '0' });
  }

  //dropdownvalues for targetsystem dropdown for master data
  getTargetSystemDropDown(): void {
    this.masterOption = this.utilityService.formatDropdownAsOrderIdAndCategoryValue(this.targetSystemOption, 'orderId', 'categoryValue');
    this.targetSystemOption.unshift({ categoryValue: this.select_targetSystem, orderId: '0' });
  }

  //dropdownvalues for targetsystem dropdown for integrity data
  getTargetSystemMaster(): void {
    this.masterOption = this.utilityService.formatDropdownAsOrderIdAndCategoryValue(this.targetSystemForMaster, 'orderId', 'categoryValue');
    this.masterOption.unshift({ categoryValue: this.select_targetSystem, orderId: '0' });
  }

  getTargetSystemIntegrity(): void {
    this.integrityOptions = this.utilityService.formatDropdownAsOrderIdAndCategoryValue(this.targetSystemForIntegrity, 'orderId', 'categoryValue');
    this.integrityOptions.unshift({ categoryValue: this.select_targetSystem, orderId: '0' });
  }

  //onchanging dropdown values 
  onChangeSelectedFieldAdaptVersion(event: any): void {
    this.selectedFieldVersionId = event;
    if (event !== "0") {
      this.dropdownAdaptVersionValue = this.adaptDropdownOptions.find(x => x.orderId == event);
      if (this.dropdownAdaptVersionValue.categoryValue === this.masterData) {
        this.targetSystemOption = this.masterOption;
      } else {
        this.targetSystemOption = this.integrityOptions;
      }
    } else if (event === "0") {
      this.dropdownAdaptVersionValue = this.adaptDropdownOptions.find(x => x.orderId == event);
      this.targetSystemOption = [];
      this.targetSystemOption.unshift({ categoryValue: this.select_targetSystem, orderId: '0' });
    }
  }

  //onchanging dropdown values 
  onChangeSelectedFieldTargetSystem(event: any): void {
    this.selectedFieldId = event;
    this.dropdownTargetSystemValue = this.targetSystemOption.find(x => x.orderId == event);
    this.defaultValue = this.dropdownTargetSystemValue.categoryValue;
  }

  onClick(): void {
    if (this.dropdownAdaptVersionValue.orderId !== 0 && this.dropdownTargetSystemValue.orderId !== 0) {
      const combinedValue = this.dropdownAdaptVersionValue.categoryValue + '-' + this.dropdownTargetSystemValue.categoryValue;
      let redirectUrl: string;
      switch (combinedValue) {
        // case 'Asset Master Data Management-EAM Infor For Transport Industry':
        //   redirectUrl = this.mdmRedirectUrl;
        //   window.open(redirectUrl, '_blank');
        //   break;
        case 'Asset Master Data Management-Maximo':
          redirectUrl = this.maximoRedirectUrl;
          window.open(redirectUrl, '_blank');
          break;
        case 'Asset Mechanical Integrity-PCMS':
          redirectUrl = this.corrossionRedirectUrl;
          window.open(redirectUrl, '_blank');
          break;
        case 'Asset Master Data Management-SAP':
          redirectUrl = this.sapRedirectUrl;
          window.open(redirectUrl, '_blank');
          break;
        case 'Asset Mechanical Integrity-GE APM':
          redirectUrl = this.geapmRedirectUrl;
          window.open(redirectUrl, '_blank');
          break;
        // case 'Asset Master Data Management-BPERP-SAP':
        //   redirectUrl = this.bperpRedirectUrl;
        //   window.open(redirectUrl, '_blank');
        //   break;
        // case 'Asset Master Data Management-CFIHOS':
        //   redirectUrl = this.cfihosRedirectUrl;
        //   window.open(redirectUrl, '_blank');
        //   break;
        case 'Asset Master Data Management-EAM Infor for Manufacturing':
          redirectUrl = this.guardianRedirectUrl;
          window.open(redirectUrl, '_blank');
          break;
        // case 'Asset Master Data Management-GenAI':
        //   redirectUrl = this.genAIRedirectUrl;
        //   window.open(redirectUrl, '_blank');
        //   break;
      }
    }
  }

  ngOnDestroy(): void {
    this.targetSystemOption = [];
    this.adaptDropdownData = [];
  }
}