export const variables = {
    ok: 'Ok',
    auth: {
        content: 'Authentication and Authorization Process is In-Progress... Please wait.'
    },
    landing: {
        select_adaptversion: 'Select Adapt Version',
        select_targetSystem: 'Select Target System',
        master_data: 'Asset Master Data Management',
        welcome_text: 'Welcome To ADAPT',
        subtitle: 'Asset Data Analysis Processing Tool'
    }
}
